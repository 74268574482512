import React from 'react'

function UserText(props) {
  return (
    <div class='w-full h-fit text-white flex justify-end mb-7'>
        <div class='max-w-[70%]'>
            <div class='bg-color6 rounded-s-xl rounded-tr-xl p-3 break-words'>
                {props.value.message}
            </div>
        </div>
    </div>
  )
}

export default UserText